import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';
import image1 from '../../../content/certs/1.jpg';
import image2 from '../../../content/certs/2.jpg';
import image3 from '../../../content/certs/3.jpg';
import image4 from '../../../content/certs/4.jpg';
import image5 from '../../../content/certs/5.jpg';
import image6 from '../../../content/certs/6.jpg';
import image7 from '../../../content/certs/7.jpg';
import image8 from '../../../content/certs/8.jpg';

const StyledMainContainer = styled.main`
  // ... existing styles

  /* Add any additional styles needed for the carousel */
  .carousel-container {
    position: relative;
    overflow: hidden;
    height: 900px; /* Adjust the height as needed */
    width: 1200px; /* Adjust the height as needed */
  }

  .carousel {
    display: flex;
    transition: transform 0.3s ease;
  }

  .carousel img {
    flex: 0 0 100%;
    max-width: 100%;
    height: 900px;
  }

  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
    font-size: 2rem;
    z-index: 1;
  }

  .carousel-button-prev {
    left: 0;
  }

  .carousel-button-next {
    right: 0;
  }

  .carousel-button:focus {
    outline: none;
  }
`;

const CertificationsPage = ({ location }) => {
  // Replace the services array with image paths for the carousel
  const imagePaths = [image1, image2, image3, image4, image5, image6, image7, image8];

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % imagePaths.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + imagePaths.length) % imagePaths.length);
  };

  return (
    <Layout location={location}>
      <Helmet title="Certifications" />

      <StyledMainContainer>
        <header>
          <h1 className="big-heading">Certifications</h1>
          <p className="subtitle">Check out my certifications.</p>
          <br />
        </header>

        {/* Add the carousel here */}
        <div className="carousel-container">
          <div className="carousel" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
            {imagePaths.map((path, index) => (
              <img key={index} src={path} alt={`Certification ${index + 1}`} />
            ))}
          </div>
          <button className="carousel-button carousel-button-prev" onClick={handlePrevSlide}>
            &lt;
          </button>
          <button className="carousel-button carousel-button-next" onClick={handleNextSlide}>
            &gt;
          </button>
        </div>
        {/* End of the carousel */}
      </StyledMainContainer>
    </Layout>
  );
};

CertificationsPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default CertificationsPage;
